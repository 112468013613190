import React from "react";
import Header from "./Header";
import classes from "./Home.module.css";
import instagram from "../../Store/inst.png";
import tweet from "../../Store/tweet.png";
import ai from "../../Store/ai.png";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import NumberCounter from "number-counter";
import { motion } from "framer-motion";
const Home = () => {
  const transition = { type: "tween", duration: 2 };
  const transition1 = { type: "tween", duration: 3 };
  const [text] = useTypewriter({
    words: ["Developer", "Designer"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 50,
  });
  return (
    <>
      <div className={classes.home_blur}></div>
      <div className={classes.home_blur1}></div>

      <div className={classes.Home_main_container} id="home">
        <Header />
        <div className={classes.logo_container}>
          <div className={classes.logo}>
            <div className={classes.heading}>
              <span>WEB </span>
              <span>KEEPS</span>
            </div>
          </div>
          <div className={classes.logo_r}>
            <div className="btn">
              <button>Work with me</button>
            </div>
          </div>
        </div>

        {/* home content */}
        <div className={classes.content_container}>
          <div className={classes.content_l}>
            <div className={classes.content_heading}>
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={transition}
              >
                WEB KEEPS
              </motion.span>
              <div className={classes.heading_sub}>
                <span>I AM A</span>
                <span className="grad">{text} </span>
                <span>
                  <Cursor cursorStyle=">" />
                </span>
              </div>
            </div>
            <div className={classes.subtitle}>
              <motion.span
                initial={{ opacity: 0, right: "100px" }}
                whileInView={{ opacity: 1, right: "0px" }}
                transition={transition1}
              >
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum
                repudiandae rerum mollitia consequatur sit.
              </motion.span>
            </div>
            <div className={classes.btn_container}>
              <div className="btn">
                <button>contact now</button>
              </div>
              <div className="grad">
                <button className={classes.btn1}>My portfolio</button>
              </div>
            </div>

            <div className={classes.socials}>
              <img
                className={classes.img}
                style={{
                  width: "2rem",
                  height: "2rem",
                  padding: "2rem 0rem 0 14rem",
                  filter: "invert(1)",
                }}
                src={instagram}
              ></img>
              <img
                className={classes.img}
                style={{
                  width: "2rem",
                  height: "2rem",
                  padding: "2rem 0rem 0 2rem",
                }}
                src={tweet}
              />
            </div>
          </div>
          <div className={classes.content_r}>
            <div className={classes.ai}>
              <img src={ai} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
