import React from "react";
import { useState } from "react";
import classes from "./Form.module.css";
import { useFormik } from "formik";
import { signupSchema } from "./schemas";

const initialValues = {
  name: "",
  email: "",
  message: "",
};
const Form = () => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signupSchema,
      onSubmit: (values, action) => {
        console.log(values);
        action.resetForm();
      },
    });
  return (
    <>
      <div className={classes.main_container} id="contact">
        <div className={classes.blur}></div>
        <div className={classes.left_form}>
          <div className={classes.form_content}>
            <span>Get In Touch</span>
            <span>Let's Work</span>
            <span>Together!</span>
          </div>
        </div>
        <div className={classes.right_form}>
          <div className={classes.main_form}>
            <form onSubmit={handleSubmit}>
              <div className={classes.entered_name}>
                <input
                  id="name"
                  type="text"
                  name="name"
                  autoComplete="off"
                  placeholder="Enter Your Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                {errors.name && touched.name ? (
                  <p className={classes.error_msg}>{errors.name}</p>
                ) : null}
              </div>

              <div className={classes.entered_Email}>
                <input
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter Your Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                {errors.email && touched.email ? (
                  <p className={classes.error_msg}>{errors.email}</p>
                ) : null}
              </div>
              <div className={classes.entered_message}>
                <input
                  id="message"
                  autoComplete="off"
                  type="text"
                  name="message"
                  placeholder="Message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                {errors.message && touched.message ? (
                  <p className={classes.error_msg}>{errors.message}</p>
                ) : null}
              </div>
              <div className="btn">
                <button type="submit">SEND MESSAGE</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
