import React from "react";
import classes from "./Project.module.css";
import pic4 from "../../Store/pic4.jpg";
import pic5 from "../../Store/pic5.jpg";
import pic6 from "../../Store/pic6.jpg";
import { ReactDOM } from "react";
import { motion } from "framer-motion";
const Project = () => {
  const transition = { type: "tween", duration: 0.2 };
  const transition1 = { type: "tween", duration: 3 };
  return (
    <div className={classes.project_container} id="project">
      <div className={classes.blur1}></div>
      <div className={classes.blur2}></div>
      <div className={classes.left_p}>
        <motion.div
          initial={{ x: "-5rem" }}
          whileInView={{ x: "0rem" }}
          transition={transition1}
          className={classes.left_container}
        >
          <span className="grad">MY LATEST</span>
          <span className="grad">WORK</span>
          <span>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla,
            dicta. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Nulla, dicta.
          </span>
          <div className="btn">
            <button className={classes.btn1}>View All Projects</button>
          </div>
          <div className={classes.box}>
            <img src={pic4} />
            <motion.div
              initial={{ opacity: 0, right: "100px" }}
              whileHover={{ opacity: 1, right: "0px", cursor: "pointer" }}
              transition={transition}
              className={classes.overlay}
            >
              <span className="grad">Project1</span>
              <span>this is the first project</span>
            </motion.div>
            <div className={classes.overlay_content}></div>
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ x: "5rem" }}
        whileInView={{ x: "0rem" }}
        transition={transition1}
        className={classes.right_p}
      >
        <div className={classes.box}>
          <img src={pic5} />
          <motion.div
            initial={{ opacity: 0, right: "100px" }}
            whileHover={{ opacity: 1, right: "0px", cursor: "pointer" }}
            transition={transition}
            className={classes.overlay}
          >
            <span className="grad">Project2</span>
            <span>this is the second project</span>
          </motion.div>
          <div className={classes.overlay_content}></div>
        </div>
        <div className={classes.box}>
          <img src={pic6} />
          <motion.div
            initial={{ opacity: 0, right: "100px" }}
            whileHover={{ opacity: 1, right: "0px", cursor: "pointer" }}
            transition={transition}
            className={classes.overlay}
          >
            <span className="grad">Project3</span>
            <span>this is the third project</span>
          </motion.div>
          <div className={classes.overlay_content}></div>
        </div>
      </motion.div>
    </div>
  );
};

export default Project;
