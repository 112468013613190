import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home/Home";
import Profile from "./components/Profile/Profile";
import Work from "./components/Work/Work";

import Project from "./components/projects/Project";
import Form from "./components/form/Form";
function App() {
  return (
    <div className="App">
      <Home />
      <Profile />
      <Work />
      <Project />
      <Form />
    </div>
  );
}

export default App;
