import classes from "./Header.module.css";
import img1 from "../../Store/Home.png";
import img2 from "../../Store/user.png";
import img3 from "../../Store/case.png";
import img4 from "../../Store/mail.png";
import img5 from "../../Store/etc.png";
import { Link } from "react-scroll";
const Header = () => {
  return (
    <div className={classes.header_main_container}>
      <div className={classes.header_container}>
        <ul>
          <li>
            <Link to="home" smooth={true} spy={true}>
              <img src={img1} className={classes.image} />
            </Link>
          </li>
          <li>
            <Link to="profile" smooth={true} spy={true}>
              <img src={img2} className={classes.image} />
            </Link>
          </li>
          <li>
            <Link to="work" smooth={true} spy={true}>
              <img src={img5} className={classes.image} />
            </Link>
          </li>
          <li>
            <Link to="project" smooth={true} spy={true}>
              <img src={img3} className={classes.image} />
            </Link>
          </li>
          <li>
            <Link to="contact" smooth={true} spy={true}>
              <img src={img4} className={classes.image} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
