import classes from "./Profile.module.css";
import img1 from "../../Store/profile.png";
import { motion } from "framer-motion";
import CountUp from "react-countup";
const Profile = () => {
  const transition = { type: "tween", duration: 3 };
  return (
    <div className={classes.profile_container} id="profile">
      <div className={classes.p_blur}></div>
      <div className={classes.p_blur1}></div>
      <div className={classes.left_p}>
        <motion.div
          initial={{ x: "-5rem" }}
          whileInView={{ x: "0rem" }}
          transition={transition}
          className={classes.img}
        >
          <img src={img1} />
        </motion.div>
      </div>
      <motion.div
        initial={{ x: "3rem" }}
        whileInView={{ x: "0rem" }}
        transition={transition}
        className={classes.right_p}
      >
        <div className={classes.profile_content}>
          <span>About me</span>
          <span>I'm a freelance full stack developer .</span>
          <span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima
            atque nobis aspernatur quibusdam, molestiae obcaecati.
          </span>
        </div>
        <div className={classes.numbers}>
          <div className={classes.number}>
            <span className="grad">
              <CountUp end={13} start={0} duration={5} />
            </span>
            <span>years of experience</span>
          </div>
          <div className={classes.number}>
            <span className="grad">
              <CountUp end={15} start={0} duration={5} />
            </span>
            <span> project completed</span>
          </div>
          <div className={classes.number}>
            <span className="grad">
              <CountUp end={12} start={0} suffix="k+" duration={5} />
            </span>
            <span> satisfied client</span>
          </div>
        </div>
        <div className={classes.btn_container}>
          <div className="btn">
            <button>contact now</button>
          </div>
          <div className="grad">
            <button className={classes.btn1}>My portfolio</button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Profile;
