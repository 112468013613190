import classes from "./Work.module.css";
import Data from "../../Store/Data";
import arrow from "../../Store/arrow.png";
import laptop from "../../Store/laptop.png";
import { motion } from "framer-motion";
const Work = () => {
  const transition = { type: "tween", duration: 3 };
  return (
    <div className={classes.work_main_container} id="work">
      <div className="blur"></div>

      <motion.div
        initial={{ x: "-3rem" }}
        whileInView={{ x: "0rem" }}
        transition={transition}
        className={classes.left_work}
      >
        <div className={classes.left_container}>
          <span className="grad">What i do !</span>
          <div className={classes.btn_container}>
            <div className="btn">
              <button>See My Work</button>
            </div>
            <img src={laptop} />
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ x: "3rem", opacity: 0 }}
        whileInView={{ x: "0rem", opacity: 1 }}
        transition={transition}
        className={classes.right_work}
      >
        {Data.map((data) => (
          <div className={classes.main_container}>
            <div className={classes.data_container}>
              <span>{data.title}</span>
              <span>{data.info}</span>
            </div>

            <div className={classes.img_container}>
              <img src={arrow} />
              <span className="grad">Learn More</span>
            </div>
            <hr />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Work;
