const Data = [
  {
    title: "UI/UX DESIGN",
    info: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ut consequatur voluptas ipsam ex provident ullam, minima cupiditate aliquid, rem earum, commodi vel dicta dolores. Cupiditate enim asperiores fuga quas?",
  },
  {
    title: "DEVELOPMENT",
    info: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ut consequatur voluptas ipsam ex provident ullam, minima cupiditate aliquid, rem earum, commodi vel dicta dolores. Cupiditate enim asperiores fuga quas?",
  },
  {
    title: "Digital Marketing",
    info: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ut consequatur voluptas ipsam ex provident ullam, minima cupiditate aliquid, rem earum, commodi vel dicta dolores. Cupiditate enim asperiores fuga quas?",
  },
  {
    title: "Product Branding",
    info: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ut consequatur voluptas ipsam ex provident ullam, minima cupiditate aliquid, rem earum, commodi vel dicta dolores. Cupiditate enim asperiores fuga quas?",
  },
];
export default Data;
